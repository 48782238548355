<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 20:37:19
-->
<template>
  <a-spin :spinning="loading" style="max-height: none">
    <div class="login-bg">
      <a-card title="管理员登陆" class="border-raduis box-shadow" style="width: 400px">
        <template #extra>
          <!-- <span @click="push({ name: 'register' })">
            <a href="javascript:;">註冊賬戶</a>
          </span> -->
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :wrapper-col="wrapperCol">
          <a-form-item label="管理员账号" name="userName">
            <a-input
              class="border-raduis"
              v-model:value="formState.userName"
              placeholder="请输入账号"
              size="large"
            >
              <!-- <template #enterButton>
              @search="getCodeFn"

                <a-button :disabled="countStatus" type="primary"
                  >獲取驗證碼<span v-show="countStatus" class="margin-left-xs">
                    {{ countDown }}s</span
                  >
                </a-button>
              </template> -->
            </a-input>
          </a-form-item>

          <a-form-item label="管理员密码" name="passWord">
            <a-input-password
              size="large"
              class="border-raduis"
              v-model:value="formState.passWord"
              placeholder="请输入密码"
            />
          </a-form-item>

          <!--<a-form-item label="">-->
          <!--    <a-radio-group v-model:value="rememberSetting">-->
          <!--        <a-radio :value="1">記住賬戶</a-radio>-->
          <!--        <a-radio :value="0">無記錄</a-radio>-->
          <!--    </a-radio-group>-->
          <!--</a-form-item>-->

          <a-form-item :wrapper-col="{ span: 24 }">
            <a-button
              type="primary"
              shape="round"
              size="large"
              style="width: 100%"
              @click="onSubmit"
              >登陆
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </a-spin>
</template>
<script  >
import { defineComponent, reactive, toRaw, ref, inject, onMounted } from "vue";
import { login, getCode } from "@/common/http/api";
import { setValue, checkValue } from "@/common/util/storage";
import { useRouter } from "vue-router";
import { message, notification } from "ant-design-vue";

export default defineComponent({
  setup() {
    const { field } = inject("globalConfig");
    const { replace, push } = useRouter();
    const loading = ref(false);
    const formRef = ref();
    const rememberSetting = ref(0);
    const countStatus = ref(false);
    const countSeconds = 60;
    const countDown = ref(countSeconds);

    // 初始化生命周期
    onMounted(() => {
      checkLogin();
    });

    // 表單數據
    const formState = reactive({
      userName: "",
      passWord: "",
    });

    // 表單規則
    const rules = {
      userName: [{ required: true, message: "请输入管理员账号", trigger: "blur" }],
      passWord: [{ required: true, message: "请输入管理员密码", trigger: "blur" }],
    };

    // 獲取驗證碼
    const getCodeFn = async () => {
      if (countStatus.value) return;
      await getCode({ account: formState.account, type: 11 });
      countStatus.value = true;
      notification.success({
        message: "驗證碼發送成功！",
        duration: 2,
      });

      const setTimer = setInterval(() => {
        countDown.value--;
        if (countDown.value === 0) {
          clearInterval(setTimer);
          countStatus.value = false;
          countDown.value = countSeconds;
        }
      }, 1000);
    };

    // 提交
    const onSubmit = async () => {
        // replace({ path: "/user" });
        // return;
      try {
        loading.value = true;
        await formRef.value.validate(); // 调用表单验证
        const loginRes = await login(toRaw(formState)); // 调用登录接口
        console.log("登陆返回",loginRes);
        setValue(field.token, loginRes); // 本地缓存token
        notification.success({
          message: "登陆成功！",
          duration: 2,
        });
          replace({ path: "/user" });
        return;
      } catch (error) {
        loading.value = false;
        console.log("提交错误：", error);
      }
    };

    // 登陆
    const checkLogin = () => {
      loading.value = true;
      message.loading({ content: "正在檢查登陸，請稍等...", key: "login", duration: 1 });
      // 检查缓存
      if (checkValue(field.token)) {
        setTimeout(() => {
          loading.value = false;
          message.success({ content: "登陸成功！", key: "login" });
          replace({ path: "/user" });
        }, 2000);
        return;
      }
      message.destroy();
      loading.value = false;
    };

    return {
      formRef,
      wrapperCol: { span: 24 },
      formState,
      rules,
      onSubmit,
      loading,
      rememberSetting,
      push,
      countDown,
      countStatus,
      getCodeFn,
    };
  },
});
</script>

<style lang="less" scoped>
.login-bg {
  height: 100vh;
  width: 100%;
  background-image: url("~@/assets/img/login-bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
