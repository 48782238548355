/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 20:00:44
 */

import axios from "./axios";

// 登錄
export const login = (data) => axios.post("/background/login", data);

// 用户信息
export const adminInfo = (data) => axios.get("/admin/info", data);

// 上传快递单号
export const addTrackingNumber = (data) =>
  axios.post("/background/addTrackingNumber", data);
